// extracted by mini-css-extract-plugin
export var blog = "blog-module--blog--a0389";
export var blog__body = "blog-module--blog__body--df268";
export var blog__footer = "blog-module--blog__footer--70f57";
export var blog__footermobile = "blog-module--blog__footermobile--cd816";
export var blog__footermobilenext = "blog-module--blog__footermobilenext--09a92";
export var blog__footermobileprev = "blog-module--blog__footermobileprev--aee3b";
export var blog__footernav = "blog-module--blog__footernav--dc06a";
export var blog__footersubscribe = "blog-module--blog__footersubscribe--79dff";
export var blog__hero = "blog-module--blog__hero--17987";
export var blog__info = "blog-module--blog__info--d67df";
export var blog__photocredit = "blog-module--blog__photocredit--5b4fc";